@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Lexend";
  src: url("../public/assets/fonts/LexendDeca-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Evolventa";
  src: url("../public/assets/fonts/Evolventa/Evolventa-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Evolventa";
  src: url("../public/assets/fonts/Evolventa/Evolventa-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}
html {
  font-size: 14px;
}
html,
body {
  padding: 0;
  margin: 0;
}

main {
  padding-top: 64px;
}

@media (max-width: 768px) {
  main {
    padding-top: 64px;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
}

/* HEADING */

h1,
h2,
h3,
h4,
h5 {
  font-family: "Evolventa", sans-serif;
  font-weight: 600;
  font-style: normal;
}
h1 {
  font-size: 70px;
  font-weight: 600;
  font-style: normal;
  line-height: 150px;
}

h2 {
  font-size: 50px;
  font-weight: 600;
  font-style: normal;
  line-height: 60px;
}

h3 {
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  line-height: 32px;
}

h4 {
  font-size: 25px;
  font-weight: 600;
  font-style: normal;
  line-height: 28px;
}

h5 {
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  line-height: 24px;
}

h6 {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 24px;
}

p,
label {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 24px;
}

.swiper {
  padding-bottom: 48px !important;
}

/* .swiper-pagination {
  bottom: -5px !important;
} */

.swiper-wrapper-no-pointer-events {
  pointer-events: none;
}

.swiper-bullet {
  display: block;
  width: 14px;
  height: 14px;
  transform: rotate(-45deg);
  border-radius: 3px;
  background: var(--Dark-green, #020d06);
  opacity: 0.4;
  cursor: pointer;
}

.swiper-bullet-light {
  display: block;
  width: 14px;
  height: 14px;
  transform: rotate(-45deg);
  border-radius: 3px;
  background: #fff;
  opacity: 0.4;
  cursor: pointer;
}

.swiper-bullet-active {
  opacity: 1;
}

.swiper-bullet-active-light {
  background: #fff;
  opacity: 1;
}

.swiper-bullet-active-green {
  background: #1ef365;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background: #5504d4 !important;
}

.dynamic-height {
  height: 500px;
}

@media (max-height: 950px) {
  .dynamic-height {
    height: 450px;
  }
}
@media (max-height: 900px) {
  .dynamic-height {
    height: 400px;
  }
}
@media (max-height: 850px) {
  .dynamic-height {
    height: 350px;
  }
}
@media (max-height: 800px) {
  .dynamic-height {
    height: 300px;
  }
}
@media (max-height: 750px) {
  .dynamic-height {
    height: 250px;
  }
}

@media (max-height: 680px) {
  .dynamic-height {
    height: 200px;
  }
}

@media screen and (max-width: 1280px) {
  .swiper-pagination-bullets-dynamic {
    margin-left: -60px !important;
  }
}

@media screen and (max-width: 1024px) {
  .swiper-pagination-bullets-dynamic {
    margin-left: 0px !important;
  }
}

.swipper-no-padding {
  padding-bottom: 2px !important;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.marquee {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.marquee h4,
.marquee p {
  display: inline-block;
  animation: marquee 6s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.audioProgress::-webkit-slider-thumb {
  opacity: 0;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-24 {
  font-size: 24px;
}

.modal-line-height {
  line-height: 19.6px;
}

.TooltipContent {
  transform-origin: var(--radix-tooltip-content-transform-origin);
  animation: scaleIn 0.1s ease-out;
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.audiomackImageDesktop {
  background: radial-gradient(
      98.8% 23.37% at 2.35% 31.54%,
      rgba(0, 0, 0, 0.81) 0%,
      rgba(1, 27, 4, 0.75006) 35.02%,
      rgba(0, 0, 0, 0) 70.24%
    ),
    radial-gradient(
      203.03% 115.64% at 50% -6.66%,
      rgba(97, 97, 97, 0) 87.56%,
      #f0efe8 87.95%
    ),
    url("../public/assets/images/singer-desktop.png");
  background-size: cover;
  background-repeat: round;
}

.audiomackImageMobile {
  background: url("../public/assets/images/singer-mobile.png");
  background-size: cover;
  background-repeat: round;
}

#wavesurfer-container ::part(region-handle-right):before {
  content: "";
  border-style: solid;
  border-width: 15px 20px 15px 0px;
  border-color: transparent #5504d4 transparent transparent;
  position: absolute;
  right: -20px;
  top: 40px;
  transform: rotate(180deg);
  border-radius: 2px;
}

#wavesurfer-container ::part(region-handle-left):before {
  content: "";
  border-style: solid;
  border-width: 15px 20px 15px 0px;
  border-color: transparent #5504d4 transparent transparent;
  position: absolute;
  left: -20px;
  top: 40px;
  border-radius: 2px;
}
